import React, { useState } from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import { useSpring, useTrail, animated, config } from "react-spring"

import SvgClients from "../components/animation/svgClients.js"

const AboutUsPageWrapper = styled.main`
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 10vw 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  padding-top: 300px;
  margin-bottom: 4rem;
  gap: 40px;
  grid-template-areas:
    ". . . title title"
    ". photo photo about about"
    ". . video video video"
    ". info video video video"
    ". info video video video"
    ". . . footer footer"
    ". clients testimonials testimonials testimonials";

  @media (max-width: 1240px) {
    grid-template-areas:
      ". . . title title"
      ". photo photo about about"
      ". video video video video"
      ". video video video video"
      ". video video video video"
      ". info info footer footer"
      ". clients clients testimonials testimonials";
  }
  @media (max-width: 680px) {
    grid-template-columns: 10vw 1fr 1fr;
    grid-template-areas:
      ". title title"
      ". about about"
      ". photo photo"
      ". video video"
      ". video video"
      ". video video"
      ". info info"
      ". footer footer"
      ". clients clients"
      ". testimonials testimonials";
    padding-bottom: 2rem;
  }
  header {
    grid-area: title;
    h1 {
      overflow: hidden;
      transform: rotate(270deg);
      transform-origin: 0 center;
      margin: 0;
      display: inline-block;
      font-size: 5rem;
      font-weight: 600;
      text-transform: lowercase;
      margin-left: 15px;
      margin-bottom: -80px;
      /* line-height: 50%; */
    }
  }
  .about {
    grid-area: about;
    font-size: 0.7rem;
    letter-spacing: 0.12rem;
    p {
      margin: 2rem 0;
      padding-right: 2rem;
    }
  }
  .photo {
    grid-area: photo;
    h2 {
      font-size: 3rem;
      overflow: hidden;
      margin-top: 0;
    }
    p {
      font-size: 0.7rem;
      line-height: 1.2rem;
      max-width: 400px;
      text-align: justify;
      padding-right: 2rem;
    }
  }

  .info {
    grid-area: info;
    display: flex;
    flex-direction: column;
    h3 {
      font-size: 1.5rem;
      overflow: hidden;
      span {
        display: block;
      }
    }
    p {
      font-size: 0.7rem;
      line-height: 1.2rem;
      max-width: 380px;
      text-align: justify;
      @media (max-width: 1240px) {
        padding-right: 2rem;
      }
    }
  }
  .video {
    grid-area: video;
    position: relative;
    video {
      background: #033060a3;

      z-index: -1;
      width: 100%;
      height: auto;
      @media (max-width: 680px) {
        display: none;
      }
    }
  }
  .video-text {
    position: absolute;
    top: -20px;
    left: 30px;
    color: #fff;
    h2 {
      font-size: 3rem;
      overflow: hidden;
      margin-top: 0;
      color: inherit;
    }
    p {
      font-size: 0.7rem;
      line-height: 1.2rem;
      max-width: 280px;
      text-align: justify;
      color: inherit;
      padding-left: 30px;
    }
    @media (max-width: 680px) {
      color: #000;
      position: relative;
      top: 0;
      left: 0;
      p {
        max-width: 380px;
        padding-left: 0;
        padding-right: 2rem;
      }
    }
  }
  .footer {
    grid-area: footer;
    display: flex;
    margin-right: 2rem;
    @media (max-width: 1240px) {
      justify-content: center;
      align-items: flex-end;
    }
    h1 {
      overflow: hidden;
      margin: 0;
      display: inline-block;
      font-size: 5rem;
      font-weight: 600;
      text-transform: lowercase;
      /* vertical-align: baseline; */
    }
    p {
      display: inline-block;
      font-size: 0.7rem;
      padding-left: 0.7rem;
      margin: 0;
    }
  }
  .clients {
    margin-top: 2rem;
    grid-area: clients;
    display: flex;
    justify-content: space-between;
    align-self: start;
    h2 {
      font-size: 3rem;
      overflow: hidden;
      margin: 0;
      padding-right: 2rem;
      color: inherit;
    }
    @media (max-width: 1240px) {
      justify-content: flex-start;
    }
  }

  .testimonials {
    grid-area: testimonials;
    position: relative;
    padding-right: 2rem;
    margin-top: 2rem;
    */ img {
      @media (max-width: 680px) {
        max-width: 300px;
        width: 100%;
        height: auto !important;
      }
    }
  }
`

const ScrollingTextContainer = styled.div`
  min-height: 40px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-right: -100%;
  overflow: hidden;
  display: flex;
  align-items: center;
`

const ScrollingTextWrapper = styled.div`
  will-change: transform;
  min-width: 100%;
  display: flex;
  align-items: center;
`

const AboutUsPageTemplate = ({ data }) => {
  const [key, setKey] = useState(1)

  const scrolledText = data.markdownRemark.frontmatter.images.map(
    (image, i) => (
      <Image
        key={i}
        style={{
          marginRight: "1rem",
          filter: "grayscale(0%)",
        }}
        fixed={image.childImageSharp.fixed}
      />
    )
  )

  const scrolledTextLength = scrolledText.length
  const scrolledTextSpeed = scrolledTextLength * 10000
  const scrolling = useSpring({
    config: {
      duration: scrolledTextSpeed,
      mass: 300,
      tension: 500,
      friction: 500,
      clamp: true,
    },
    from: { transform: "translate3d(20%,0,0)" },
    to: { transform: "translate3d(-100%,0,0)" },
    reset: true,
    onRest: () => {
      setKey(key + 1)
    },
  })

  const weItems = [
    "are content designers, content creators, content producers.",
    "create Your images.",
    "shoot Your videos.",
    "design Your graphics.",
    "are thinkers and doers.",
    "are SOCOlab",
  ]

  const trail = useTrail(weItems.length, {
    delay: 600,
    opacity: 1,
    transform: "scale(1)",
    from: { opacity: 0, transform: "scale(0.9)" },
  })

  const fadeIn = useSpring({
    config: config.slow,
    from: { opacity: 0, transform: "scale(0.91)" },
    opacity: 1,
    transform: "scale(1)",
  })

  const fadeInRight = useSpring({
    delay: 2000,
    config: config.slow,
    from: { transform: "translate3d(10%, 0, 0", opacity: 0 },
    to: { transform: "translate3d(0%,0,0)", opacity: 1 },
  })
  const fadeInLeft = useSpring({
    delay: 1500,
    config: config.slow,
    from: { transform: "translate3d(-10%, 0, 0", opacity: 0 },
    to: { transform: "translate3d(0%,0,0)", opacity: 1 },
  })

  return (
    <AboutUsPageWrapper>
      <animated.header style={fadeIn}>
        <h1>we</h1>
      </animated.header>
      <div className="about">
        {trail.map((props, index) => (
          <animated.p style={props} key={weItems[index]}>
            {weItems[index]}
          </animated.p>
        ))}
      </div>
      <animated.div style={fadeInLeft} className="photo">
        <h2>photo</h2>
        <p>
          It all starts with love for photography. We believe that no matter
          what project we do, the outcome needs to be perfect so our team make
          sure all your expectations are exceeded. In our studio we do
          commercial campaigns, lookbooks, e-commerce, packshots and still life
          photography.
        </p>
      </animated.div>
      <animated.div style={fadeInLeft} className="info">
        <div>
          <h3>
            <span>retouch</span>
            <span>and</span>
            <span>graphic design</span>
          </h3>
          <p>
            In case You already have great pictures we also do professional
            retouching and graphic design. Our approach is fresh, natural and
            modern with attention to details.
          </p>
        </div>
        <div>
          <h3>production</h3>
          <p>
            Whatever project You have in mind we are able to implement it. We
            adapt to your preferred method of working, always making sure we can
            deliver the right results to fair prices. Starting with Your phone
            call we can manage every step of the photo and video production.
          </p>
        </div>
      </animated.div>
      <animated.div style={fadeInRight} className="video">
        <video autoPlay playsInline disablePictureInPicture muted loop>
          <source
            src="https://res.cloudinary.com/socolab-dev/video/upload/v1594893561/video/about-us-page/aboutus_2_ikkn0h.webm"
            type="video/webm"
          />
          <source
            src="https://res.cloudinary.com/socolab-dev/video/upload/v1594893556/video/about-us-page/aboutus_1_g4z8va.mp4"
            type="video/mp4"
          />
        </video>
        <div className="video-text">
          <h2>video</h2>
          <p>
            Starting with concept, through production and video postproduction
            we take care of capturing Your vision. No matter what type of video
            You need we are ready to meet the task. We make all sorts of video:
            commercial, noncommercial, backstage, e-commerce and social media.
          </p>
        </div>
      </animated.div>
      <div className="footer">
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <h1>we</h1>
          <p>are ready for You</p>
        </div>
      </div>
      <div className="clients">
        <h2>clients</h2>
        <span
          style={{ maxWidth: "3rem", display: "flex", alignItems: "center" }}
        >
          <SvgClients />
        </span>
      </div>
      <div className="testimonials">
        <ScrollingTextContainer>
          <AnimatedText style={scrolling}>
            {scrolledText}
            {scrolledText}
            {scrolledText}
          </AnimatedText>
        </ScrollingTextContainer>
      </div>
    </AboutUsPageWrapper>
  )
}

const AnimatedText = animated(ScrollingTextWrapper)

export default AboutUsPageTemplate
