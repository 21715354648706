import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout.js"
import AboutUsPageTemplate from "../templates/aboutUsPageTemplate"

const aboutUs = ({ data, location }) => {
  return (
    <Layout location={location}>
      <AboutUsPageTemplate data={data} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutUsPageQuery {
    markdownRemark(frontmatter: { title: { eq: "about-us" } }) {
      id
      frontmatter {
        title
        images {
          childImageSharp {
            fixed(height: 40, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

export default aboutUs
